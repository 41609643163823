<template>
  <Box>
    
    <Row v-if="!isLoading">
      <Column>
        <VehicleList v-if="domain" :isAdmin="true" :start="startSub" :query="$route.query" :isAdminList="true" />
      </Column>
    </Row>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>
    
  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue"; 
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import VehicleList from "@/views/portal/vehicles/list/Vehicles.vue"
import ConstUtils  from '@/utils/ConstUtils.js';
import Company from "@/domain/model/company/Company.js";

export default {
  name: 'portals-admin-vehicles',
  components: {
    VehicleList, LoadingRow,
    Box, Row, Column,
  },
  props: {
    isAdmin: { type: Boolean, default: true },
  },
  data() {
    return {
      startSub: false,
      isLoading: true,
      loadingMessage: null,
      MC: new MC(),
      Consts: ConstUtils,

    }
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                 ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
      this.domain.session().with(new Company(this.domain));
      this.startSub = true;
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  },
}
</script>